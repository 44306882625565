/* src/components/LoadingSpinner.css */
.spinner-box{
    background: white;
    padding: 30px 45px;
    border-radius: 8px;
    /* box-shadow: 0px 0px 12px -4px black; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.loading-container {
    position: fixed; /* Centers the spinner relative to the entire viewport */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensures it stays above other content */
}

.spinner {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #007bff; /* Primary Blue Color */
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin 1s linear infinite;
}

.loading-text {
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    text-align: center;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
