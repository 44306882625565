
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", serif;
  -webkit-user-select: none;
  -ms-user-select: none;    
  user-select: none; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
