/* ErrorBoundary.css */
.error-boundary-container {
    text-align: center;
    padding: 50px;
    border: 2px solid #ff4b5c;
    border-radius: 20px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.2);
    background: linear-gradient(135deg, #ffe259, #ffa751);
    color: #333;
    max-width: 600px;
    margin: 100px auto;
    animation: fadeIn 1s ease-in-out;
}

.error-icon {
    font-size: 4rem;
    animation: bounce 1.5s infinite;
}

.error-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 20px;
    animation: slideInDown 1s ease;
}

.error-description {
    font-size: 1.2rem;
    margin: 20px 0;
}

.error-button {
    padding: 12px 24px;
    background: #ff4b5c;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
}

.error-button:hover {
    background: #e63946;
    transform: scale(1.1);
}

.error-details {
    margin-top: 20px;
    text-align: left;
    font-size: 1rem;
}

.error-details summary {
    cursor: pointer;
    font-weight: bold;
    color: #333;
}

/* Keyframe Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
}

@keyframes slideInDown {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
